import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Advanced_User/OpenHAB_IP_Camera_Binding/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Pure-FTPd on a Raspberry Pi",
  "path": "/Advanced_User/openHABian_on_Raspberry_Pi/Projects/FTP_Snapshot/Pure-FTPd/",
  "dateChanged": "2018-10-18",
  "author": "Mike Polinowski",
  "excerpt": "FTP (File Transfer Protocol) can be used to transfer files between a Raspberry Pi and another computer. openHABian aims to provide a self-configuring Linux system setup specific to the needs of every Raspberry Pi user. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_openHABian.png",
  "social": "/images/Search/AU_SearchThumb_openHABian.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Pure-FTPd on a Raspberry Pi' dateChanged='2018-10-18' author='Mike Polinowski' tag='INSTAR IP Camera' description='FTP (File Transfer Protocol) can be used to transfer files between a Raspberry Pi and another computer. openHABian aims to provide a self-configuring Linux system setup specific to the needs of every Raspberry Pi user. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_openHABian.png' twitter='/images/Search/AU_SearchThumb_openHABian.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/openHABian_auf_Raspberry_Pi/Projekte/FTP_Snapshot/Pure-FTPd/' locationFR='/fr/Advanced_User/openHABian_on_Raspberry_Pi/Projects/FTP_Snapshot/Pure-FTPd/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "pure-ftpd-on-a-raspberry-pi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#pure-ftpd-on-a-raspberry-pi",
        "aria-label": "pure ftpd on a raspberry pi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pure-FTPd on a Raspberry Pi`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installation"
        }}>{`Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#basic-configurations"
        }}>{`Basic Configurations`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#ftp-home-directory-virtual-user-and-user-group"
        }}>{`FTP Home Directory, Virtual User and User Group`}</a></li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`We want to use the FTP (File Transfer Protocol) to transfer snapshots between our INSTAR IP camera an our Raspberry Pi. Follow the steps below to set up an FTP server.`}</p>
    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h2>
    <ol>
      <li parentName="ol">{`Use your SSH terminal (Windows user can use the free Terminal from `}<a parentName="li" {...{
          "href": "https://mobaxterm.mobatek.net/download.html"
        }}>{`Mobaterm`}</a>{`) to connect to your Pi (`}<strong parentName="li">{`default login: user = openhabian and password = openhabian`}</strong>{` for an `}<a parentName="li" {...{
          "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/"
        }}>{`openHABian Installation`}</a>{`).`}</li>
      <li parentName="ol">{`Install Pure-FTPd using the following command line in Terminal: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`sudo apt-get install pure-ftpd`}</code>{`.`}</li>
    </ol>
    <h2 {...{
      "id": "basic-configurations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#basic-configurations",
        "aria-label": "basic configurations permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Basic Configurations`}</h2>
    <ol>
      <li parentName="ol">{`We need to create a new user group named `}<strong parentName="li">{`ftpgroup`}</strong>{` and a new user named `}<strong parentName="li">{`ftpuser`}</strong>{` for FTP users, and make sure this "user" has no login privilege and no home directory:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`groupadd`}</span>{` ftpgroup
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useradd`}</span>{` ftpuser -g ftpgroup -s /sbin/nologin -d /dev/null`}</code></pre></div>
    <h2 {...{
      "id": "ftp-home-directory-virtual-user-and-user-group",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ftp-home-directory-virtual-user-and-user-group",
        "aria-label": "ftp home directory virtual user and user group permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FTP Home Directory, Virtual User and User Group`}</h2>
    <ol>
      <li parentName="ol">{`We already created a public directory inside the `}<em parentName="li">{`/.node-red/static/`}</em>{` folder in our `}<em parentName="li">{`openhabian`}</em>{` home directory. Make a new directory named ftp with a subfolder for our first camera (in my case it will be `}<em parentName="li">{`officecam`}</em>{`):`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` /home/openhabian/.node-red/static/ftp/officecam/`}</code></pre></div>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`You will be asked for your password, which is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`openhabian`}</code>{` by default - but you might have changed that in an `}<a parentName="li" {...{
          "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/OpenHAB_Setup/#securing-your-installation"
        }}>{`earlier step`}</a>{`.`}</li>
      <li parentName="ol">{`Make sure the directory is accessible for `}<strong parentName="li">{`ftpuser`}</strong>{`, so our camera will be able to read and write files from/to it:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` -R ftpuser:ftpgroup /home/openhabian/.node-red/static/ftp/officecam/`}</code></pre></div>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Create a virtual user named `}<strong parentName="li">{`upload`}</strong>{`, mapping the virtual user to `}<strong parentName="li">{`ftpuser`}</strong>{` and  `}<strong parentName="li">{`ftpgroup`}</strong>{`, setting home directory `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/home/openhabian/.node-red/static/ftp/`}</code>{`, and record password of the user in database. Notice that we set the path to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/home/openhabian/.node-red/static/ftp/`}</code>{` and NOT to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/home/openhabian/.node-red/static/ftp/officecam`}</code>{` - this way we can use the same FTP user for all our cameras and just have to assign different upload directories in the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Features/FTP/"
        }}>{`FTP Configuration`}</a>{` of our cameras - `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`./officam`}</code>{` in case of our first camera. In your case `}<em parentName="li">{`it might make sense`}</em>{` to use a different FTP user with a different home directory for each camera? You can make that decision here:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` pure-pw `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useradd`}</span>{` upload -u ftpuser -g ftpgroup -d /home/openhabian/.node-red/static/ftp/ -m`}</code></pre></div>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Type in a password for this virtual user once you are prompted for it.`}</li>
      <li parentName="ol">{`And next, set up a virtual user database by typing:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` pure-pw mkdb`}</code></pre></div>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Last but not least, define an authentication method by making a link of file `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/etc/pure-ftpd/conf/PureDB`}</code>{`:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ln`}</span>{` -s /etc/pure-ftpd/conf/PureDB /etc/pure-ftpd/auth/60puredb`}</code></pre></div>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Restart the program:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`sudo service pure-ftpd restart`}</code></pre></div>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`You can test the FTP Upload with your INSTAR camera using the FTP Test function:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c3c36b9bbd1da42782d1b1ff36b80e00/e9beb/Node-RED_OpenHAB_Projects_Pure-FTPd_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACzElEQVQoz22PXUhTcRjGD1JC5gez1JjiRws1Zpo6Nd1OudZEoxQzofkFYRejRSjJJtvZ2dzm0lAHBYE3XhVhQniym5ISqRASayUzhMQ8286cSJvZ2dn52PmHLELLh+fufZ/397zQ9b4n1/qmWi1Yi2Wq2fR8r7FmE3YVnWy3vVD1YfcevaUpMhjcCgQCDMM4nU7IPr5on1q/i3msz9z6p2uGXUae4sgErh9f65/02DHvxNy6z4u7lpZcLheO47Ozs9CG3wcAy0dY8L84mqd/AZ6JTgmvR3ZWKi4UZ2VlxkCQTFoJEQTBMAzHcQAAfpcjPE9RFE3TDMPQNAMA8Ho855UK5cVauUIef/DAOVi2E6ZpmmX3IZMkGQ6HWZZlGIbnebfbXXvpcm1DQ119fZpAUA3De8j7hiORSPQ0juNNqtYOtaaptS1HmFFdJd0JUxRFkmR0g9ul7e3tQCBAURTD7NT2uN3yC4oKmbQShvNyRHKp7A85Wu8fMstxJEn+DfsIolxSknH0yHGRKEmQVF5RBhGElw5T4RAJeB4AMDY2ptVpUYtFi/Y5HI4fgeDO4VCI5yLrBFFRWnrimDA5NQWKgcrKJRDh8/EA0FwEALAV3FLWKEVZooLcU7nlZ6pq632e79FfAAAbfr+kpDguMS5BkBB7OFZWDUP+tWUwPxp59wB8Gf9JhhobGktrSmE9LG47fVLRPjr9zfsY8w7e9w09JBacRcVFhxLj0rOz04TpSoUCcn1e2FycIT5Ob36dW1ldrZHXiOsKyhBJXmFhsfIK9mZ+9f2HlZevV1/NLH9yant1Go3mdlfXrZuanu5uSG9ADOZ+1DpgGxw2IkhuXm5yQnJqfEqKMFOUn2829A44RmzDQ3bHiNXeb4vKarXZbBarFdLpdNqeO/pencloNJvNarVa1aJq62hTqVQ3OjuNqAlFjCiCoAhiQlHTXv0Gmpsm+RQLD40AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c3c36b9bbd1da42782d1b1ff36b80e00/e4706/Node-RED_OpenHAB_Projects_Pure-FTPd_01.avif 230w", "/en/static/c3c36b9bbd1da42782d1b1ff36b80e00/d1af7/Node-RED_OpenHAB_Projects_Pure-FTPd_01.avif 460w", "/en/static/c3c36b9bbd1da42782d1b1ff36b80e00/b6582/Node-RED_OpenHAB_Projects_Pure-FTPd_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c3c36b9bbd1da42782d1b1ff36b80e00/a0b58/Node-RED_OpenHAB_Projects_Pure-FTPd_01.webp 230w", "/en/static/c3c36b9bbd1da42782d1b1ff36b80e00/bc10c/Node-RED_OpenHAB_Projects_Pure-FTPd_01.webp 460w", "/en/static/c3c36b9bbd1da42782d1b1ff36b80e00/87ca7/Node-RED_OpenHAB_Projects_Pure-FTPd_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c3c36b9bbd1da42782d1b1ff36b80e00/81c8e/Node-RED_OpenHAB_Projects_Pure-FTPd_01.png 230w", "/en/static/c3c36b9bbd1da42782d1b1ff36b80e00/08a84/Node-RED_OpenHAB_Projects_Pure-FTPd_01.png 460w", "/en/static/c3c36b9bbd1da42782d1b1ff36b80e00/e9beb/Node-RED_OpenHAB_Projects_Pure-FTPd_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c3c36b9bbd1da42782d1b1ff36b80e00/e9beb/Node-RED_OpenHAB_Projects_Pure-FTPd_01.png",
              "alt": "INSTAR Camera FTP Setup",
              "title": "INSTAR Camera FTP Setup",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go back to your Terminal and go to the upload directory and list all files:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` /home/openhabian/.node-red/static/ftp/officecam
ll`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/13405dd7007b0262f1c3b77a0aa325bc/e9beb/Node-RED_OpenHAB_Projects_Pure-FTPd_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "3.4782608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAR0lEQVQI1y3FSw6AIAxAQU+kG6UWqHyKCd7/QM/EuJjMUudGfTasr+xJEG+EVtDbkf7vFRmOz0HOQjbFLqXUhMbjE1Mg2ckLUyYiG0VKkS0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/13405dd7007b0262f1c3b77a0aa325bc/e4706/Node-RED_OpenHAB_Projects_Pure-FTPd_02.avif 230w", "/en/static/13405dd7007b0262f1c3b77a0aa325bc/d1af7/Node-RED_OpenHAB_Projects_Pure-FTPd_02.avif 460w", "/en/static/13405dd7007b0262f1c3b77a0aa325bc/b6582/Node-RED_OpenHAB_Projects_Pure-FTPd_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/13405dd7007b0262f1c3b77a0aa325bc/a0b58/Node-RED_OpenHAB_Projects_Pure-FTPd_02.webp 230w", "/en/static/13405dd7007b0262f1c3b77a0aa325bc/bc10c/Node-RED_OpenHAB_Projects_Pure-FTPd_02.webp 460w", "/en/static/13405dd7007b0262f1c3b77a0aa325bc/87ca7/Node-RED_OpenHAB_Projects_Pure-FTPd_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/13405dd7007b0262f1c3b77a0aa325bc/81c8e/Node-RED_OpenHAB_Projects_Pure-FTPd_02.png 230w", "/en/static/13405dd7007b0262f1c3b77a0aa325bc/08a84/Node-RED_OpenHAB_Projects_Pure-FTPd_02.png 460w", "/en/static/13405dd7007b0262f1c3b77a0aa325bc/e9beb/Node-RED_OpenHAB_Projects_Pure-FTPd_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/13405dd7007b0262f1c3b77a0aa325bc/e9beb/Node-RED_OpenHAB_Projects_Pure-FTPd_02.png",
              "alt": "INSTAR Camera FTP Setup",
              "title": "INSTAR Camera FTP Setup",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Your test upload should be listed below - a JPG image file with a name that starts with a `}<em parentName="p">{`T`}</em>{` and a `}<em parentName="p">{`timestamp`}</em>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8497b9b3052260f37fae23bf4985e76f/e9beb/Node-RED_OpenHAB_Projects_Pure-FTPd_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "4.3478260869565215%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAATElEQVQI1wXBUQ5AMBBAQTeSkvJXJC21W1ZFev+bPDNdiAGxldoSYgtv27neDbWVrImoB1ILhxVSVrI+yP0RxbBykkPP6Aem2eEnxw/FRiRaxkmYtwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8497b9b3052260f37fae23bf4985e76f/e4706/Node-RED_OpenHAB_Projects_Pure-FTPd_03.avif 230w", "/en/static/8497b9b3052260f37fae23bf4985e76f/d1af7/Node-RED_OpenHAB_Projects_Pure-FTPd_03.avif 460w", "/en/static/8497b9b3052260f37fae23bf4985e76f/b6582/Node-RED_OpenHAB_Projects_Pure-FTPd_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8497b9b3052260f37fae23bf4985e76f/a0b58/Node-RED_OpenHAB_Projects_Pure-FTPd_03.webp 230w", "/en/static/8497b9b3052260f37fae23bf4985e76f/bc10c/Node-RED_OpenHAB_Projects_Pure-FTPd_03.webp 460w", "/en/static/8497b9b3052260f37fae23bf4985e76f/87ca7/Node-RED_OpenHAB_Projects_Pure-FTPd_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8497b9b3052260f37fae23bf4985e76f/81c8e/Node-RED_OpenHAB_Projects_Pure-FTPd_03.png 230w", "/en/static/8497b9b3052260f37fae23bf4985e76f/08a84/Node-RED_OpenHAB_Projects_Pure-FTPd_03.png 460w", "/en/static/8497b9b3052260f37fae23bf4985e76f/e9beb/Node-RED_OpenHAB_Projects_Pure-FTPd_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8497b9b3052260f37fae23bf4985e76f/e9beb/Node-RED_OpenHAB_Projects_Pure-FTPd_03.png",
              "alt": "INSTAR Camera FTP Setup",
              "title": "INSTAR Camera FTP Setup",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      